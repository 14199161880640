<template>
  <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="dialog">
    <div class="head-container">
      <el-upload class="filter-item" ref="upload" :action="uploadUrl" :headers="uploadHeaders" :before-upload="handleBeforeUpload" :on-success="handleUploadSuccess" :on-error="handleUploadError" :show-file-list="false" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
        <el-button type="primary" class="filter-item" icon="el-icon-upload2" :loading="uploading">{{btnName}}</el-button>
      </el-upload>
      <!-- <el-button type="primary" @click="downloadTemplate" class="filter-item" icon="el-icon-download" :loading="downloadLoading">模板下载</el-button> -->
    </div>

    <el-tabs v-model="activeName" type="card">
      <el-tab-pane label="库房数据" name="warehouse">
        <div class="v" v-if="uploaded && uploaded.done && !uploaded.warehouse.err">
          <span>导入结果：共{{uploaded.warehouse.total}}条,成功{{uploaded.warehouse.success}}条,失败{{uploaded.warehouse.fail}}条</span>&nbsp;
          <span>错误详情</span>
          <el-table :data="uploaded.warehouse.detail" border style="width: 100%;">
            <el-table-column prop="code" header-align="center"  align="center" label="库房编码" />
            <el-table-column prop="name" min-width="200" show-overflow-tooltip label="库房名称" />
            <el-table-column prop="error" label="错误信息" width="250" show-overflow-tooltip />
          </el-table>
        </div>
        <div v-if="uploaded && uploaded.done && uploaded.warehouse.err">
          <span>{{uploaded.stock.err}}</span>
        </div>
      </el-tab-pane>
      <el-tab-pane label="库存数据" name="stock">
        <div class="v" v-if="uploaded && uploaded.done && !uploaded.stock.err">
          <span>导入结果：共{{uploaded.stock.total}}条,成功{{uploaded.stock.success}}条,失败{{uploaded.stock.fail}}条</span>&nbsp;
          <div class="h c">
            <span>错误详情</span> &nbsp;
            <el-button @click="downloadErr" :loading="downloadLoading" type="danger" size="mini">错误导出</el-button>
          </div>
          &nbsp;
          <el-table :data="uploaded.stock.detail" border style="width: 100%;">
            <el-table-column prop="entCode" width="120" label="经销商ERP编码" />
            <el-table-column prop="entName" width="200" label="经销商名称" />
            <el-table-column prop="stockCode" width="80" header-align="center" align="center" label="库房编码" />
            <el-table-column prop="goodsErpCode" width="150" show-overflow-tooltip label="商品ERP编码" />
            <el-table-column prop="goodsName" min-width="100" show-overflow-tooltip label="商品名称" />
            <el-table-column prop="specName" min-width="100" show-overflow-tooltip label="规格" />
            <el-table-column prop="stock" width="100" label="库存数量" />
            <el-table-column prop="error" label="错误信息" width="150" show-overflow-tooltip />
          </el-table> 
          <!-- 库房编码	商品ERP编码	商品名称	库存数量 -->
        </div>
        <div v-if="uploaded && uploaded.done && uploaded.stock.err">
          <span>{{uploaded.stock.err}}</span>
        </div>
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
</template>
<script>
import config from "@/config";
import { getToken } from "@/utils/auth";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";
export default {
  data() {
    return {
      activeName: "warehouse",
      uploading: false,
      entId: null,
      entName:null,
      uploadHeaders: {},
      downloadLoading: false,
      dialog: false,
      uploaded:{done:false,warehouse:{},stock:{}},
    };
  },
  computed: {
    btnName() {
      return this.uploading ? "导入中" : "选择文件";
    },
    uploadUrl() {
      return config.hosts.request + "api/stock/import/" + this.entId;
    },
  },
  methods: {
    handleBeforeUpload() {
      this.uploading = true;
    },
    handleUploadSuccess(res) {
      this.uploaded.done = true;
      this.uploaded.warehouse = res.warehouse;
      this.uploaded.stock = res.stock;
      this.uploading = false;
      //console.log(this.$parent);
     
    },
    handleUploadError(err) {
      //this.errInfo = err;
      this.$message.err(err);
      this.uploading = false;
    },
    downloadTemplate() {
      this.downloadLoading = true;
      download("api/distributorGroup/members/template")
        .then((result) => {
          downloadFile(result, "经销商组成员模板", "xlsx");
          this.downloadLoading = false;
        })
        .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
    downloadErr(){
      if(!this.uploaded.stock.detail) return;
      // downloadFile(this.uploaded.warehouse.detail, "经销商库存未导入信息", "xlsx");
      this.downloadLoading = true;
      import("@/utils/export2Excel").then(excel => {
        const tHeader = [
          "经销商ERP编码",
          "经销商名称",
          "库房编码",
          "商品ERP编码",
          "商品名称",
          "规格",
          "库存数量",
          "错误信息"
        ];
        const filterVal = [
          "entCode",
          "entName",
          "stockCode",
          "goodsErpCode",
          "goodsName",
          "specName",
          "stock",
          "error"
        ];
        const data = this.formatJson(filterVal, this.uploaded.stock.detail);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: this.entName+"_库存未导入信息"
        });
        this.downloadLoading = false;
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v =>
        filterVal.map(j => {          
            return v[j];          
        })
      );
    },
    resetForm(entId,entName) {
      this.uploadHeaders.Authorization = "Bearer " + getToken();
      this.entId = entId;
      this.entName = entName;
      this.uploaded={done:false,warehouse:{},stock:{}};
      this.dialog = true;
    },
  },
};
</script>